<template>
  <div class="container">
    <div class="resume__title title">Резюме</div>
    <form class="resume-form" @submit.prevent="sendRequest" ref="form">
      <div class="resume-form__item">
        <div class="resume-form__label">Ваше имя *</div>
        <div class="resume-form__input">
          <FormText
            :label="'Ваше имя'"
            :required="true"
            v-model="request.name"
          />
        </div>
      </div>

      <div class="resume-form__item">
        <div class="resume-form__label">Телефон</div>
        <div class="resume-form__input">
          <FormText
            :label="'Телефон'"
            :required="true"
            :pattern="'+# (###) ### ## ##'"
            v-model="request.phone"
          />
        </div>
      </div>

      <div class="resume-form__item">
        <div class="resume-form__label">E-mail</div>
        <div class="resume-form__input">
          <FormText :label="'E-mail'" v-model="request.e_mail" />
        </div>
      </div>

      <div class="resume-form__item">
        <div class="resume-form__label">Желаемая должность *</div>
        <div class="resume-form__input">
          <FormSelect
            :label="'Желаемая должность'"
            :items="vacancies"
            v-model="request.vacancy"
          />
        </div>
      </div>

      <FormUpload :label="'Резюме'" v-model="request.file" />

      <div class="resume-form__item">
        <div class="resume-form__label">Текст резюме *</div>
        <div class="resume-form__input">
          <FormArea :label="'Текст резюме'" v-model="request.resume_text" />
        </div>
      </div>

      <div class="personal-form__item">
        <div class="form-default__consent">
          <label class="checkbox">
            <input
              name="consent"
              type="checkbox"
              value="Y"
              checked
              v-model="isConsent"
            />
            <span class="checkbox__indicator">
              <svg class="icon icon-check ">
                <use xlink:href="#check"></use>
              </svg>
            </span>
            <span class="checkbox__description">
              Я даю свое согласие на
              <a href="/privacy-policy" target="_blank">
                обработку персональных данных
              </a>
            </span>
          </label>
        </div>
      </div>

      <button
        type="submit"
        class="resume-form__submit btn"
        :disabled="!isConsent"
      >
        Отправить
      </button>
    </form>

    <SimpleModal v-if="isShowModal" @close="isShowModal = false" />
  </div>
</template>

<script>
import FormText from "@/components/forms/form-text";
import FormSelect from "@/components/forms/form-select";
import FormUpload from "@/components/forms/form-upload";
import FormArea from "@/components/forms/textarea";

import store from "@/store/index.js";

export default {
  components: {
    FormText,
    FormSelect,
    FormUpload,
    FormArea,
  },

  beforeRouteEnter(to, from, next) {
    store.dispatch("VACANCIES_FETCH_DATA").then(() => {
      next();
    });
  },

  computed: {
    vacancies() {
      const list = [];
      this.$store.state.vacancies.vacancies.forEach((item) =>
        item.items.forEach((el) => list.push({ id: el.id, name: el.name }))
      );
      return list;
    },
    message() {
      return this.$store.state.messageModalSettings.response.message;
    },
    error() {
      return this.$store.state.messageModalSettings.response.error;
    },
  },

  data() {
    return {
      request: {
        name: null,
        phone: null,
        e_mail: null,
        vacancy: null,
        file: null,
        resume_text: null,
      },
      errors: {},
      isConsent: true,
      isShowModal: false,
    };
  },

  methods: {
    sendRequest() {
      this.errors = {};

      const data = this.request;

      this.$store
        .dispatch("VACANCIES_SEND_RESUME", data)
        .then((response) => {
          this.$store.commit("SET_MESSAGE_MODAL_DATA", response.data);
          this.isShowModal = true;
        })
        .catch((error) => {
          if (error.response.data.errors)
            this.errors = error.response.data.errors;

          this.$store.commit("SET_MESSAGE_MODAL_DATA", error.response.data);
          this.isShowModal = true;
        });
    },
  },
};
</script>
