<template>
  <div class="personal-form__item">
    <div class="personal-form__label">
      {{ label }}
      <template v-if="required">*</template>
    </div>
    <div class="resume-form__file">
      <label class="resume-form__file-btn">
        Выберите файл
        <input
          style="display: none;"
          type="file"
          ref="input"
          :required="required"
          @change="previewFiles"
        />
      </label>
      <div class="resume-form__file-label">
        {{ fileName.length > 0 ? fileName : "Файл не выбран" }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      fileName: "",
    };
  },

  methods: {
    previewFiles() {
      this.fileName = event.target.files[0].name;
      this.$emit("update:modelValue", event.target.files);
    },
  },
};
</script>
